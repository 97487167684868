// IMPORTANT: this file should contain ONLY primitive (e.g. string/number) constants, i.e. NO REACT COMPONENTS.
export const MMY_TAB_ID = 'MMY';
export const VIN_TAB_ID = 'VIN';
export const LICENSE_PLATE_TAB_ID = 'LICENSE_PLATE';
export const ADDRESS_TAB_ID = 'ADDRESS';

export const MMY_TAB = {
  id: MMY_TAB_ID,
  label: 'Year/Make/Model',
  mobileLabel: 'Make/Model',
  secondaryLinkLabel: 'Year, Make & Model',
};
export const VIN_TAB = {
  id: VIN_TAB_ID,
  label: 'VIN',
  secondaryLinkLabel: 'VIN',
};
export const LICENSE_PLATE_TAB = {
  id: LICENSE_PLATE_TAB_ID,
  label: 'License Plate',
  secondaryLinkLabel: 'License Plate',
};
export const ADDRESS_TAB = {
  id: ADDRESS_TAB_ID,
  label: 'Address',
};
export const ADDRESS_TAB_CHAL2 = {
  id: ADDRESS_TAB_ID,
  label: 'VIN Lookup',
};

export const TABS = {
  DEFAULT: [MMY_TAB, VIN_TAB, LICENSE_PLATE_TAB],
  LICENSE_PLATE: [LICENSE_PLATE_TAB, VIN_TAB, MMY_TAB],
  YMM_SUPPRESSED: [LICENSE_PLATE_TAB, VIN_TAB],
  VIN: [VIN_TAB, LICENSE_PLATE_TAB, MMY_TAB],
};

export const TAB_LINKS_ORDER_BY = {
  [MMY_TAB_ID]: { FIRST: LICENSE_PLATE_TAB, SECOND: VIN_TAB },
  [VIN_TAB_ID]: { FIRST: VIN_TAB, SECOND: LICENSE_PLATE_TAB },
  [LICENSE_PLATE_TAB_ID]: { FIRST: LICENSE_PLATE_TAB, SECOND: VIN_TAB },
};
