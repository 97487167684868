import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get, noop } from 'lodash';
import { connect } from 'react-redux';
import { venomHistory } from 'client/utils/history/venom-history';
import { getAppraisalLink } from 'site-modules/shared/utils/appraisal-link-constructor';
import { checkVinInventory } from 'site-modules/shared/utils/appraisal/check-vin-inventory';
import { APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID } from 'client/site-modules/shared/constants/appraisal/appraisal';
import { AppraisalTabsContext } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tabs-context';
import { AppraisalTabs } from 'site-modules/shared/components/appraisal/appraisal-tabs-container/appraisal-tabs';
import { usePrevious } from 'site-modules/shared/hooks/use-previous';
import { getQuery } from 'client/utils/location';

export function AppraisalTabsContextProvider({ WrappedComponent, ...props }) {
  const [vin, setVin] = useState('');
  const [licensePlate, setLicensePlate] = useState('');
  const [stateCode, setStateCode] = useState(props.initialStateCode);
  const [mmy, setMmy] = useState({});
  const [styleIds, setStyleIds] = useState([]);
  const [isReadyForRedirect, setIsReadyForRedirect] = useState(false);
  const [squishStyles, setSquishStyles] = useState(null);
  const [isAppraiserPageOpened, setIsAppraiserPageOpened] = useState(false);

  const {
    isAppExtensionPage,
    isVdpEmbedded,
    isLookUpStyleView,
    location,
    ctaBtnColor,
    onFindVinModalOpen,
    onFindVinModalClose,
    onMultipleVinsModalOpen,
    onMultipleVinsModalClose,
    isProjectEveStyle,
  } = props;

  const prevIsReadyForRedirect = usePrevious(isReadyForRedirect);

  const setDefaultButtonStateForCachedPage = useCallback(({ persisted }) => {
    if (persisted) {
      setIsReadyForRedirect(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('pageshow', setDefaultButtonStateForCachedPage);

    return () => {
      setIsReadyForRedirect(false);
      window.removeEventListener('pageshow', setDefaultButtonStateForCachedPage);
    };
  }, [setDefaultButtonStateForCachedPage]);

  useEffect(() => {
    if (isReadyForRedirect && isReadyForRedirect !== prevIsReadyForRedirect) {
      const { make, model, year } = mmy;
      const { modsId, emo } = getQuery(location);

      const appraisalPathLink = getAppraisalLink({
        make,
        model,
        year,
        queryParams: {
          vin,
          styleIds: styleIds.join(','),
          modsId,
          emo,
        },
        isAppExtensionPage,
      });

      if (isVdpEmbedded) {
        window.open(appraisalPathLink, '_blank');
        setIsReadyForRedirect(false);
        setIsAppraiserPageOpened(true);
      } else if (!isLookUpStyleView) {
        venomHistory.push(appraisalPathLink);
      }

      if (vin) {
        checkVinInventory(vin, APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID, noop);
      }
    }
  }, [
    isAppExtensionPage,
    isLookUpStyleView,
    isReadyForRedirect,
    isVdpEmbedded,
    location,
    mmy,
    prevIsReadyForRedirect,
    styleIds,
    vin,
  ]);

  const queryVin = get(location, 'query.vin', '').toUpperCase();
  const contextProps = useMemo(
    () => ({
      licensePlate,
      setLicensePlate,
      mmy,
      setMmy,
      vin,
      setVin,
      queryVin,
      stateCode,
      setStateCode,
      styleIds,
      setStyleIds,
      squishStyles,
      setSquishStyles,
      isReadyForRedirect,
      setIsReadyForRedirect,
      isAppraiserPageOpened,
      ctaBtnColor,
      onFindVinModalOpen,
      onFindVinModalClose,
      onMultipleVinsModalOpen,
      onMultipleVinsModalClose,
      isProjectEveStyle,
    }),
    [
      ctaBtnColor,
      isAppraiserPageOpened,
      isProjectEveStyle,
      isReadyForRedirect,
      licensePlate,
      mmy,
      onFindVinModalClose,
      onFindVinModalOpen,
      onMultipleVinsModalClose,
      onMultipleVinsModalOpen,
      queryVin,
      squishStyles,
      stateCode,
      styleIds,
      vin,
    ]
  );

  return (
    <AppraisalTabsContext.Provider value={contextProps}>
      <WrappedComponent {...props} vin={vin} />
    </AppraisalTabsContext.Provider>
  );
}

AppraisalTabsContextProvider.propTypes = {
  WrappedComponent: PropTypes.elementType.isRequired,
  ctaBtnColor: PropTypes.string,
  onFindVinModalOpen: PropTypes.func,
  onFindVinModalClose: PropTypes.func,
  onMultipleVinsModalOpen: PropTypes.func,
  onMultipleVinsModalClose: PropTypes.func,
};

AppraisalTabsContextProvider.defaultProps = {
  ctaBtnColor: 'success',
  onFindVinModalOpen: noop,
  onFindVinModalClose: noop,
  onMultipleVinsModalOpen: noop,
  onMultipleVinsModalClose: noop,
};

// A little hack to test AppraisalTabsContextProvider's logic that is passed to the context
export function AppraisalTabsContainerUI(props) {
  return <AppraisalTabsContextProvider {...props} WrappedComponent={AppraisalTabs} />;
}

export const mapStateToProps = state => ({
  isMobile: get(state, 'mobile'),
  location: get(state, 'pageContext.location'),
  referer: get(state, 'pageContext.page.referer'),
  profileOfferVin: get(state, 'profile.data.idm.appraisalOffersHistory[0].vin'),
  isAppExtensionPage: get(state, 'pageContext.isAppExtensionPage'),
});

export const AppraisalTabsContainer = connect(mapStateToProps)(AppraisalTabsContainerUI);
