import React, { useState, useEffect, useCallback, useRef, useId } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { trim } from 'lodash';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Input from 'reactstrap/lib/Input';

// Luckdragon
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { AppraisalModel } from 'client/data/models/appraisal';

// Constants
import { ESCAPE_KEY_VALUE, ARROW_DOWN_KEY_VALUE, ARROW_UP_KEY_VALUE } from 'site-modules/shared/constants/key-values';
import { TrackingConstant } from 'client/tracking/constant';

// Hooks
import { useClickOutside } from 'site-modules/shared/hooks/use-click-outside';
import { useDebounce } from 'client/site-modules/shared/hooks/use-debounce';

// Utils
import { EventToolbox } from 'client/utils/event-toolbox';

// Components
import { AppraisalTabsSubmitButton } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tabs-submit-button';
import { FieldError } from 'site-modules/shared/components/field-error/field-error';
import { LoadingSpinner } from 'site-modules/shared/components/loading-spinner/loading-spinner';

import './ymm-search.scss';

export const CREATIVE_ID = 'appraisal_vehicle_entry_search_form';

const ListItem = ({ option, handleOptionClick, inputValue, index, focusedIndex, handleKeyDown, disabled, vehicle }) => {
  const isSelected = inputValue === option;
  const itemRef = useRef(null);

  useEffect(() => {
    if (index === focusedIndex && itemRef.current) {
      itemRef.current.focus();
    }
  }, [focusedIndex, index]);

  const handleClick = useCallback(() => {
    handleOptionClick(option, vehicle);
  }, [handleOptionClick, option, vehicle]);

  return (
    <li role="option" aria-selected={isSelected}>
      {disabled ? (
        <div className="px-1 py-0_75 size-16">{option}</div>
      ) : (
        <button
          onClick={handleClick}
          className="option-btn border-0 c-pointer w-100 px-1 py-0_75 size-16 text-left"
          ref={itemRef}
          onKeyDown={handleKeyDown}
          data-tracking-id="submit_ymm"
        >
          {option}
        </button>
      )}
    </li>
  );
};

ListItem.propTypes = {
  option: PropTypes.string,
  handleOptionClick: PropTypes.func.isRequired,
  inputValue: PropTypes.string,
  index: PropTypes.number,
  focusedIndex: PropTypes.number,
  handleKeyDown: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  vehicle: PropTypes.shape({}),
};

ListItem.defaultProps = {
  option: '',
  inputValue: '',
  index: null,
  focusedIndex: null,
  disabled: false,
  vehicle: null,
};

export const YmmSearchUI = ({
  setModelValue,
  setIsReadyForRedirect,
  setMmy,
  autoCompleteData,
  btnTrackingId,
  ctaText,
  btnClassName,
  hasAppraisalBtn,
  isReadyForRedirect,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isResultsListVisible, setIsResultsListVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [showError, setShowError] = useState(false);
  const errorId = useId();
  const inputRef = useRef(null);
  const wrapperRef = useRef(null);
  const inputId = 'ymm-search-input';
  const listId = 'ymm-search-list';
  const { id: autoCompleteId, results: autoCompleteResults } = autoCompleteData;
  const resultsLength = autoCompleteResults?.length;

  const validate = useCallback(() => {
    setShowError(true);
  }, []);

  const hideResults = useCallback(() => {
    setFocusedIndex(-1);
    setIsResultsListVisible(false);
  }, []);

  useClickOutside(wrapperRef, hideResults, isResultsListVisible);

  useEffect(() => {
    if (autoCompleteId) {
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
        event_data: {
          action_category: TrackingConstant.USER_ACTION_CATEGORY,
          action_cause: TrackingConstant.ACTION_CAUSE_USER_INPUT,
          action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
          subaction_name: TrackingConstant.RENDER_FIRST_RESULTS,
          creative_id: CREATIVE_ID,
        },
      });
    }
  }, [autoCompleteId]);

  useEffect(() => {
    if (!!autoCompleteId && isLoading) {
      setIsLoading(false);
    }
  }, [isLoading, autoCompleteId]);

  const updateAutoCompleteFilter = useCallback(
    val => {
      setModelValue('autoCompleteFilter', AppraisalModel, trim(val));
    },
    [setModelValue]
  );

  const updateAutoCompleteFilterDebounced = useDebounce(updateAutoCompleteFilter, 500);

  const onChange = useCallback(
    e => {
      const value = e.target.value;
      setIsLoading(true);
      setInputValue(value);
      setShowError(false);
      updateAutoCompleteFilterDebounced(value);
    },
    [updateAutoCompleteFilterDebounced]
  );

  const focusInput = useCallback(() => {
    inputRef.current.focus();
  }, []);

  const handleOptionClick = useCallback(
    (value, vehicle) => {
      setInputValue(value);
      focusInput();
      hideResults();
      setMmy({
        make: vehicle.niceMake,
        model: vehicle.niceModel,
        year: vehicle.year,
      });
      setIsReadyForRedirect(true);
    },
    [focusInput, hideResults, setIsReadyForRedirect, setMmy]
  );

  const handleFocus = useCallback(() => {
    setIsResultsListVisible(true);

    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        action_category: TrackingConstant.USER_ACTION_CATEGORY,
        action_cause: TrackingConstant.ACTION_CAUSE_CLICK,
        action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
        subaction_name: TrackingConstant.FOCUS_FIRST_SEARCH_FIELD,
        creative_id: CREATIVE_ID,
      },
    });
  }, []);

  const handleKeyDown = useCallback(
    event => {
      if (event.key === ESCAPE_KEY_VALUE) {
        focusInput();
        hideResults();
      } else if (event.key === ARROW_DOWN_KEY_VALUE) {
        event.preventDefault();
        setFocusedIndex(prevIndex => Math.min(prevIndex + 1, resultsLength - 1));
      } else if (event.key === ARROW_UP_KEY_VALUE) {
        event.preventDefault();
        setFocusedIndex(prevIndex => Math.max(prevIndex - 1, 0));
      }
    },
    [focusInput, hideResults, resultsLength]
  );

  return (
    <div className="ymm-search" data-tracking-parent={CREATIVE_ID}>
      <Label className="size-12 text-cool-gray-30 mb-0_5" htmlFor={inputId}>
        Search for your vehicle
      </Label>
      <div className="tab-content-row d-flex flex-column flex-md-row">
        <div className="search-input-wrapper pos-r mw-100 flex-grow-1" ref={wrapperRef}>
          <span aria-hidden className="icon-search pos-a size-16 text-blue-40" />
          <LoadingSpinner classes="pos-a size-16 text-blue-40" isLoading={isReadyForRedirect} />
          <FormGroup
            className={classnames('mb-0', {
              'has-danger': showError,
            })}
          >
            <Input
              onChange={onChange}
              value={inputValue}
              className="search-input"
              placeholder="Year, Make, and Model"
              list={listId}
              id={inputId}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown}
              aria-haspopup="listbox"
              aria-expanded={isResultsListVisible}
              aria-controls={listId}
              innerRef={inputRef}
              role="combobox"
              disabled={isReadyForRedirect}
              {...(showError ? { ariaDescribedBy: showError, ariaInvalid: true } : {})}
            />
            <FieldError
              id={errorId}
              error={showError ? 'Please enter your Year, Make and Model, and select your vehicle from the list' : null}
              isLiveRegion
              classes="mt-0_25"
            />
          </FormGroup>
          {isResultsListVisible && inputValue && (
            <ul className="options-list list-unstyled pos-a w-100 bg-white rounded" role="listbox" id={listId}>
              {isLoading || !resultsLength ? (
                <ListItem
                  disabled
                  option={isLoading ? 'Loading...' : `Sorry, we couldn't find anything`}
                  handleOptionClick={handleOptionClick}
                  handleKeyDown={handleKeyDown}
                />
              ) : (
                autoCompleteResults.map((veh, index) => (
                  <ListItem
                    key={veh.id}
                    option={`${veh.year} ${veh.make} ${veh.model} ${veh.trim}`}
                    handleOptionClick={handleOptionClick}
                    inputValue={inputValue}
                    index={index}
                    focusedIndex={focusedIndex}
                    handleKeyDown={handleKeyDown}
                    vehicle={veh}
                  />
                ))
              )}
            </ul>
          )}
        </div>
        {hasAppraisalBtn && (
          <AppraisalTabsSubmitButton
            onSubmit={validate}
            buttonClassName={classnames('py-0_75 btn-responsive-md-down', btnClassName)}
            data-test="appraise-btn"
            data-tracking-id={btnTrackingId}
            isDisabled={isReadyForRedirect}
            isSubmitting={isReadyForRedirect}
            ctaText={ctaText}
          />
        )}
      </div>
      {!hasAppraisalBtn && <hr className="my-1_5" />}
    </div>
  );
};

YmmSearchUI.propTypes = {
  setModelValue: PropTypes.func.isRequired,
  autoCompleteData: PropTypes.shape({}),
  setMmy: PropTypes.func.isRequired,
  mmy: PropTypes.shape({}),
  ctaText: PropTypes.string,
  btnClassName: PropTypes.string,
  btnTrackingId: PropTypes.string,
  hasAppraisalBtn: PropTypes.bool,
  setIsReadyForRedirect: PropTypes.func.isRequired,
  isReadyForRedirect: PropTypes.bool,
};

YmmSearchUI.defaultProps = {
  autoCompleteData: {},
  mmy: {},
  ctaText: 'Appraise My Car',
  btnClassName: null,
  btnTrackingId: null,
  hasAppraisalBtn: false,
  isReadyForRedirect: false,
};

export const stateToPropsConfig = {
  autoCompleteData: bindToPath('autoCompleteData', AppraisalModel),
};

export const YmmSearch = connectToModel(YmmSearchUI, stateToPropsConfig);
