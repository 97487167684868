import React from 'react';
import { useAppraisalTabsContext } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tabs-context';
import { YmmSearch } from 'site-modules/shared/components/appraisal/appraisal-tabs/ymm-search/ymm-search';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';
import { AppraiseByMmyTabContent } from './appraise-by-mmy-tab-content';

export const AppraiseByMmyTabContainer = props => {
  const { mmy, setMmy, isReadyForRedirect, setIsReadyForRedirect } = useAppraisalTabsContext();

  const extendedProps = {
    ...props,
    mmy,
    setMmy,
    isReadyForRedirect,
    setIsReadyForRedirect,
  };

  if (props.isAppraisalLanding) {
    return (
      <Experiment name="EMO-2405" showDefault>
        <Recipe name="ctrl" isDefault>
          <AppraiseByMmyTabContent {...extendedProps} />
        </Recipe>
        <Recipe name="chal0">
          <AppraiseByMmyTabContent {...extendedProps} />
        </Recipe>
        <Recipe name="chal1">
          <YmmSearch {...extendedProps} />
          <AppraiseByMmyTabContent {...extendedProps} isEmo2405Chal />
        </Recipe>
        <Recipe name="chal2">
          <YmmSearch {...extendedProps} isEmo2405Chal hasAppraisalBtn />
        </Recipe>
      </Experiment>
    );
  }

  return <AppraiseByMmyTabContent {...extendedProps} />;
};
