import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { noop, isNil, size } from 'lodash';

// Models
import { getVinStylesData } from 'client/data/models/vehicle-vin';

// Utils
import { validation } from 'site-modules/shared/components/form-validation/validation';
import { EventToolbox } from 'client/utils/event-toolbox';
import { transformVin } from 'site-modules/shared/utils/appraiser/appraiser-validation-helper';
import { venomHistory } from 'client/utils/history/venom-history';

// Constants
import { TrackingConstant } from 'client/tracking/constant';
import { APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID } from 'client/site-modules/shared/constants/appraisal/appraisal';
import { APPRAISE_BY_VIN_TAB_EVENT_DATA } from 'site-modules/shared/components/appraisal/appraisal-tabs/constants';

// Context
import { useAppraisalTabsContext } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tabs-context';

// Components
import { VinTabContent } from 'site-modules/shared/components/appraisal/appraisal-tabs/vin-tab/vin-tab-content';
import { VinTabInput } from 'site-modules/shared/components/appraisal/appraisal-tabs/vin-tab/vin-tab-input';
import { AppraisalTabsSubmitButton } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tabs-submit-button';

export class VinTabContainerUI extends Component {
  state = {
    vinInputValue: this.props.vin,
    isVinValid: validation.validateVIN(this.props.vin),
    isVinValidLength: validation.validateVinLength(this.props.vin),
    isVinEdited: false,
    wasFormSubmitted: false,
    isSubmitting: false,
  };

  static getDerivedStateFromProps(props, state) {
    const { queryVin, profileOfferVin, isLookUpStyleView } = props;
    const { isVinEdited, vinInputValue } = state;

    const prepopulatedVin = transformVin(queryVin || profileOfferVin || '');

    if (prepopulatedVin && !vinInputValue && !isVinEdited) {
      if (prepopulatedVin === profileOfferVin && !isLookUpStyleView) {
        EventToolbox.fireTrackAction({
          event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
          event_data: {
            action_name: TrackingConstant.ACTION_APPRAISE_VEHICLE,
            action_category: TrackingConstant.USER_ACTION_CATEGORY,
            subaction_name: TrackingConstant.VIN_AUTOFILL,
            creative_id: APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID,
          },
        });
      }

      return {
        vinInputValue: prepopulatedVin,
        isVinValid: validation.validateVIN(prepopulatedVin),
        isVinValidLength: validation.validateVinLength(prepopulatedVin),
      };
    }

    return null;
  }

  componentDidMount() {
    window.addEventListener('pageshow', this.setDefaultButtonStateForCachedPage);
  }

  componentDidUpdate(prevProps) {
    const { isAppraiserPageOpened } = this.props;

    // Reset submitting state when /appraisal-value/ page is opened
    if (isAppraiserPageOpened && !prevProps.isAppraiserPageOpened) {
      this.resetIsSubmitting();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('pageshow', this.setDefaultButtonStateForCachedPage);
  }

  onVinChange = e => {
    const value = transformVin(e.target.value);
    this.setState({
      isVinEdited: true,
      vinInputValue: value,
      isVinValid: validation.validateVIN(value),
      isVinValidLength: validation.validateVinLength(value),
      wasFormSubmitted: false,
      isSubmitting: false,
    });
  };

  onSubmit = async e => {
    const {
      creativeId,
      isLookUpStyleView,
      ctaText,
      setContextVin,
      setContextStyleIds,
      setStylesAndVin,
      isSellInCityState,
      isWIDG1125chal1,
    } = this.props;
    const { isVinValid, vinInputValue } = this.state;

    e.preventDefault();

    this.setState({
      wasFormSubmitted: true,
      isSubmitting: true,
    });

    if (!isVinValid) {
      this.fireVinErrorTracking(TrackingConstant.SUBACTION_VIN_VALIDATION, TrackingConstant.VIN_FORMAT_INVALID);
      this.resetIsSubmitting();
      return;
    }

    const styles = await getVinStylesData(vinInputValue);
    if (isWIDG1125chal1 && isSellInCityState) {
      venomHistory.push(`/sell-car/instant-cash-offer/?icoVin=${vinInputValue}`);
      return;
    }

    const styleIds = styles.map(({ styleId }) => styleId);

    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        ...APPRAISE_BY_VIN_TAB_EVENT_DATA,
        creative_id: creativeId,
        subaction_name: TrackingConstant.SUBACTION_VIN_ENTRY,
        value: vinInputValue,
      },
    });
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_END,
      event_data: {
        ...APPRAISE_BY_VIN_TAB_EVENT_DATA,
        creative_id: creativeId,
        action_cause: TrackingConstant.ACTION_CAUSE_LINK_CLICK,
        subaction_name: TrackingConstant.SUBMIT_VIN,
        value: isLookUpStyleView ? ctaText : TrackingConstant.VIN,
      },
    });

    setContextVin(vinInputValue);
    setContextStyleIds(styleIds);
    setStylesAndVin(styles, vinInputValue);
  };

  setDefaultButtonStateForCachedPage = ({ persisted }) => {
    if (persisted) {
      this.resetIsSubmitting();
    }
  };

  fireVinErrorTracking = (subactionName, value) => {
    const { creativeId } = this.props;
    const { vinInputValue } = this.state;

    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        ...APPRAISE_BY_VIN_TAB_EVENT_DATA,
        creative_id: creativeId,
        subaction_name: subactionName,
        value,
      },
    });
    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        ...APPRAISE_BY_VIN_TAB_EVENT_DATA,
        creative_id: creativeId,
        subaction_name: TrackingConstant.SUBACTION_VIN_ERROR,
        value: `${vinInputValue}_${subactionName}_${value}`,
      },
    });
  };

  resetIsSubmitting = () => {
    this.setState({ isSubmitting: false });
  };

  render() {
    const {
      vin,
      profileOfferVin,
      creativeId,
      setStylesAndVin,
      isLookUpStyleView,
      ctaText,
      squishStyles,
      isVdpEmbedded,
      isBackgroundStyleTabEnabled,
      isProjectEveStyle,
      ...restProps
    } = this.props;
    const { vinInputValue, isVinValid, isVinValidLength, wasFormSubmitted, isSubmitting } = this.state;

    const hasSquishStyles = size(squishStyles) > 0;
    const isSquishVinInvalid = wasFormSubmitted && isVinValid && !isNil(squishStyles) && !hasSquishStyles;
    const isError = wasFormSubmitted && (!isVinValid || isSquishVinInvalid);

    return (
      <VinTabContent
        {...restProps}
        vin={vin}
        vinInputValue={vinInputValue}
        isVinValid={isVinValid}
        wasFormSubmitted={wasFormSubmitted}
        isSubmitting={isSubmitting}
        profileOfferVin={profileOfferVin}
        resetIsSubmitting={this.resetIsSubmitting}
        fireVinErrorTracking={this.fireVinErrorTracking}
        isSquishVinInvalid={isSquishVinInvalid}
        isBackgroundStyleTabEnabled={isBackgroundStyleTabEnabled}
        inputSlot={
          <VinTabInput
            vinInputValue={vinInputValue}
            onVinChange={this.onVinChange}
            isError={isError}
            isVinValid={isVinValid}
            isVinValidLength={isVinValidLength}
            isSquishVinInvalid={isSquishVinInvalid}
          />
        }
        submitButtonSlot={
          <AppraisalTabsSubmitButton
            ctaText={ctaText}
            onSubmit={this.onSubmit}
            isSubmitting={isSubmitting}
            containerClassName={classnames({
              'mt-0_5': isLookUpStyleView,
            })}
            buttonClassName={classnames('btn-responsive-sm-down', {
              'py-0_5': isVdpEmbedded || isBackgroundStyleTabEnabled || isProjectEveStyle,
            })}
            isDisabled={isSquishVinInvalid || isSubmitting}
            data-test="appraise-btn-by-vin"
          />
        }
      />
    );
  }
}

VinTabContainerUI.propTypes = {
  vin: PropTypes.string,
  queryVin: PropTypes.string,
  setContextVin: PropTypes.func.isRequired,
  setContextStyleIds: PropTypes.func.isRequired,
  squishStyles: PropTypes.arrayOf(PropTypes.shape({})),
  setStylesAndVin: PropTypes.func,
  isLookUpStyleView: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  profileOfferVin: PropTypes.string,
  hasAddressToVinDrawer: PropTypes.bool,
  isBackgroundStyleTabEnabled: PropTypes.bool,
  addressToVinDrawer: PropTypes.node,
  creativeId: PropTypes.string,
  ctaText: PropTypes.string,
  isVdpEmbedded: PropTypes.bool,
  isEval4351Chal: PropTypes.bool,
  onFindVinModalOpen: PropTypes.func,
  onFindVinModalClose: PropTypes.func,
  isAppraiserPageOpened: PropTypes.bool,
  isSellInCityState: PropTypes.bool,
  isProjectEveStyle: PropTypes.bool,
};

VinTabContainerUI.defaultProps = {
  vin: '',
  queryVin: '',
  squishStyles: null,
  setStylesAndVin: noop,
  isLookUpStyleView: false,
  isFullWidth: false,
  profileOfferVin: '',
  hasAddressToVinDrawer: false,
  isBackgroundStyleTabEnabled: false,
  addressToVinDrawer: null,
  creativeId: APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID,
  ctaText: 'Appraise My Car',
  isVdpEmbedded: false,
  isEval4351Chal: false,
  onFindVinModalOpen: noop,
  onFindVinModalClose: noop,
  isAppraiserPageOpened: false,
  isSellInCityState: false,
  isProjectEveStyle: false,
};

export function VinTabContainer(props) {
  const {
    vin,
    queryVin,
    setVin: setContextVin,
    setStyleIds: setContextStyleIds,
    squishStyles,
    onFindVinModalOpen,
    onFindVinModalClose,
    isAppraiserPageOpened,
    isProjectEveStyle,
  } = useAppraisalTabsContext();

  return (
    <VinTabContainerUI
      {...props}
      vin={vin}
      queryVin={queryVin}
      setContextVin={setContextVin}
      setContextStyleIds={setContextStyleIds}
      squishStyles={squishStyles}
      onFindVinModalOpen={onFindVinModalOpen}
      onFindVinModalClose={onFindVinModalClose}
      isAppraiserPageOpened={isAppraiserPageOpened}
      isProjectEveStyle={isProjectEveStyle}
    />
  );
}
